.about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100vh;
    overflow: hidden;
  }

.about-screen {
    width: 100%;
    height: 100%;
    background-color: rgba(238, 238, 238, 0.131);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-slider h3 {
    position: relative;
    font-size: 3vw;
    margin: 0;
}

.slider-box {
    height: 3vw;
    width: 20vw;
    position: relative;
}

.slider-box span {
    position: absolute;
    font-size: 1.8rem;
    font-weight: bold;
    color: orange;
    white-space: pre;
    opacity: 0
}

.about-slider:hover .slider-box span:nth-child(1){
    display: flex;
    animation-name: aboutFly;
    animation-duration: 2s;
}

.about-slider:hover .slider-box span:nth-child(2){
    display: flex;
    animation-name: aboutFly;
    animation-duration: 2s;
    animation-delay: 2s;
    animation-timing-function: ease-out;
}

.about-slider:hover .slider-box span:nth-child(3){
    display: flex;
    animation-name: aboutFly;
    animation-duration: 2s;
    animation-delay: 4s;
    animation-timing-function: ease-out;
}

.about-slider:hover .slider-box span:nth-child(4){
    display: flex;
    animation-name: aboutFly;
    animation-duration: 2s;
    animation-delay: 6s;
    animation-timing-function: ease-out;
}

.about-slider:hover .slider-box span:nth-child(5){
    display: flex;
    animation-name: aboutFly;
    animation-duration: 2s;
    animation-delay: 8s;
    animation-timing-function: ease-out;
}

.about-slider:hover .slider-box span:nth-child(6){
    display: flex;
    animation-name: aboutFly;
    animation-duration: 2s;
    animation-delay: 10s;
    animation-timing-function: ease-out;
}

.about-slider:hover .slider-box span:nth-child(7){
    display: flex;
    animation-name: aboutFly;
    animation-duration: 4s;
    animation-delay: 12s;
    animation-timing-function: linear;
}


.about-slider:hover .slider-box span:nth-child(8){
    display: flex;
    animation-name: aboutFly;
    animation-duration: 3s;
    animation-delay: 16s;
    animation-timing-function: ease-out;
}

.about-slider:hover .slider-box span:nth-child(9){
    display: flex;
    animation-name: aboutFly;
    animation-duration: 2s;
    animation-delay: 19s;
    animation-timing-function: ease-in;
}



@keyframes aboutFly {
    0% {
        opacity:0;
        transform: scale(0.5);
        left: -20%
    }
    80% {
        opacity:10;
        transform: scale(1);
        left: 100%
    }
    100% {
        opacity: 0;
        transform: scale(0.4);
        left: 100%
    }
}



@media only screen and (max-width: 768px) and (orientation: portrait) {
    .about-screen h3 {
        position: relative;
        font-size: 8vw;
    
    }
}