.animation-m-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    height: 100vh;
    width: 100%;
    margin-bottom: 30vh;
    box-sizing: border-box;
  }

.animations-m-info-block {
    width: 100%;
    transition: 0.7s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.animations-m-info {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 2vw;
}

.animation-m-info-text {
    position: absolute;
    bottom: 1vh;
    width: 100%;
    box-sizing: border-box;
    padding: 0 2vw;
    display: flex;
    align-items: center;
    color: white;
    font-family: 'Cantarell', sans-serif;
    font-weight: 100;
    font-size: 3.5vw;
    z-index: 12;
}

.animation-m-info-text a, a:visited {
    text-decoration: none;
    color: orange;
}

.animations-m-screen {
    width: 100%;
    height: 50vh;
    transition: 2s;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    transform-style: preserve-3d;
    align-items: center;
}

.animations-m-screen div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.animations-m-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50vh;
    border-bottom: 1px solid rgb(91, 91, 91,0.8);
    border-top: 1px solid rgb(91, 91, 91,0.8);
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in;
    cursor: pointer;
}

.animations-m-modal video {
    position: relative;
    bottom: 4vh;

}