.content-container-animation {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    height: 100vh;
    width: 100%;
    margin-bottom: 30vh;
    box-sizing: border-box;
  }

.animations-info-fakeblock {
    width: 40%;
    height: 70vh;
}

.animations-info-block {
    width: 40%;
    height: 70vh;
    transition: 0.7s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.animations-info {
    width: 70%;
    height: 80%;
    position: relative;
    box-sizing: border-box;
    background: linear-gradient(135deg, rgba(255,197,143,0.7396419749540442) 0%, rgba(147,147,147,0.33347950996804976) 17%, rgba(255,255,255,0.593983711648722) 39%, rgba(255,255,255,0.2998660645898985) 67%);
    padding: 2vw;
    font-size: 0.9vw
}

.animations-info:before {
    content:'';
    position: absolute;
    display: inline-block;
    height: 100%;
    width: 1px;
    border-right: 1px solid rgb(255, 168, 45);
    top: -5%;
    left: 0
}

.animations-info:after {
    content:'';
    position: absolute;
    display: inline-block;
    height: 1px;
    width: 120%;
    border-bottom: 1px solid rgb(191, 191, 191);
    top: 0%;
    left: -5%
}

.animation-info-text {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 8%;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    align-items: center;
    color: white;
    font-size: 0.8vw;
    z-index: 12;
}

.animation-info-text a, a:visited {
    text-decoration: none;
    color: orange;
}

.anim-highlight {
    color: black;
    font-weight: 600;
    font-size: 1.1vw;
}

.anim-though {
    width: 2vw;
    height: 2vw;
    border-radius: 3vw;
    transition: 0.2s;
    background-color: #777777;
    color: white;
    text-align: center;
    line-height: 2vw;
    font-size: 1.2vw;
    font-weight: 900;
    margin-left: -1vw;
    border-top: 1px solid rgba(149, 149, 149, 0.2);
    border-right: 1px solid rgba(149, 149, 149, 0.2);
    transform: rotate(-15deg);
}

.animations-screen {
    position: absolute;
    width: 100%;
    height: 70vh;
    transition: 2s;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    transform-style: preserve-3d;
    transform: perspective(2000px) scale(1) rotateY(-45deg);
}

.animations-screen div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.animations-modal {
    position: absolute;
    display: flex;
    width: 60vw;
    height: 40vw;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in;
    background-color: rgb(91, 91, 91);
    transform: perspective(1000px) scale(1) rotateY(0deg) rotateX(0deg) rotateZ(0deg) scale(1);
    cursor: pointer;
}

.video-selector {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 0, 0, 0);
    z-index: 2;
}

.anim-close-vid {
    position: absolute;
    top: 0;
    right: 15vw;
    height: 2vw;
    width: 2vw;
    border-radius: 2vw;
    font-size: 60%;
    text-align: center;
    z-index: 2;
    outline: none;
    background-color: #434343;
    color: white;
    border: none;
    cursor: pointer;
    animation-name: appear;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

@keyframes appear {
    0%{
        opacity: 0
    }
    100% {
        opacity: 1
    }
}


@media only screen and (max-width: 768px) {
    .animations-info-block {
        width: 50%;    
    }

    .animations-info{
        font-size: 1.6vw;
    }

    .anim-highlight {
        font-size: 1.8vw;
    }

    .animations-screen {
        transform: perspective(500px) scale(1.5) rotateY(-35deg) translateX(-20vw);
    }


    .anim-close-vid {
        position: absolute;
        top: 0;
        right: 15vw;
        height: 4vw;
        width: 4vw;
        border-radius: 4vw;
        z-index: 2;
        outline: none;
        background-color: #434343;
        color: white;
        border: none;
    }

    .animation-info-text {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 8%;
        box-sizing: border-box;
        padding: 5px;
        display: flex;
        align-items: center;
        color: white;
        font-size: 1.2vw;
        z-index: 12;
    }


}