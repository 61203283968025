.project-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-screen {
    width: 25%;
    height: 70vh;
    display:flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease-in-out;
}

.project {
    position: relative;
    width: 20vw;
    height: 20vw;
    border-radius: 20vw;
    border: 1px solid rgb(191, 191, 191);
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: 1s;
    font-size: 2vw;
    cursor: pointer;
    z-index: 1;
}

.project:hover{
    transform: scale(1.1);
    z-index: 2;
}

.project-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project div {
    transition: 0.5s ease-in-out;
}

.project h3 {
    transition: 0.5s ease-in-out;
    margin: 0;
    font-weight:100
}

.project-phrase {
    font-size: 1vw;
    color: rgb(255, 123, 0);
}

.project p {
    font-size: 0.9vw;
    color: rgb(46, 46, 46);
}

.img-link {
    filter:grayscale(0.5);
    transition: 0.2s;
    max-width: 15vw;
}

.img-link:hover {
    filter:grayscale(0)
}

.project-bg {
    position: absolute;
    top:-5vw;
    left:-5vw;
    width: 30vw;
    height: 30vw;
    opacity: 0.4;
    transition: 0.6s;
    background: url(../img/project-01.svg);
}

.project-bg:hover {
    opacity: 0.9;
}


.project-bg2 {
    position: absolute;
    top:-5vw;
    left:-5vw;
    width: 30vw;
    height: 30vw;
    opacity: 0.2;
    transition: 0.6s;
    background: url(../img/project-02.svg);
}

.project-bg2:hover {
    opacity: 0.9;
}

.project-bg-fmeasy {
    transform: rotate(70deg);
    animation-name: bg-fmeasy;
    animation-duration: 37s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.project-bg2-fmeasy {
    transform: rotate(60deg);
    animation-name: bg2-fmeasy;
    animation-duration: 42s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes bg-fmeasy {
    0%{
        transform: rotate(70deg);
    }
    100%{
        transform: rotate(-290deg);
    }    
}


@keyframes bg2-fmeasy {
    0%{
        transform: rotate(60deg);
    }
    100%{
        transform: rotate(-300deg);
    }    
}



.project-bg-spacefication {
    transform: rotate(0deg);
    animation-name: bg-spacefication;
    animation-duration: 40s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.project-bg2-spacefication {
    transform: rotate(15deg);
    animation-name: bg2-spacefication;
    animation-duration: 36s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}


@keyframes bg-spacefication {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }    
}


@keyframes bg2-spacefication {
    0%{
        transform: rotate(15deg);
    }
    100%{
        transform: rotate(375deg);
    }    
}



.project-bg-spfx {
    animation-name: bg-spfx;
    animation-duration: 46s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.project-bg2-spfx {
    animation-name: bg2-spfx;
    animation-duration: 32s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes bg-spfx {
    0%{
        transform: rotate(-45deg);
    }
    100%{
        transform: rotate(305deg);
    }    
}


@keyframes bg2-spfx {
    0%{
        transform: rotate(-70deg);
    }
    100%{
        transform: rotate(290deg);
    }    
}

.spfx-pic {
    position: absolute;
    top: 0;
    width: 130%;
    height: 40vh;
    overflow: hidden;
    background-color: rgba(79, 79, 79, 0.95);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

.spfx-pic img {
    max-width: 100%;
    transition: 0.3s;
    height: auto;
}

.spfx-buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.spfx-buttons button {
    outline: none;
    border: none;
    width: 6vh;
    height: 6vh;
    border-radius: 6vh;
    cursor: pointer;
    transition: 0.3s;
    background-color: rgb(255, 206, 142);
}

.spfx-buttons button:hover{
    background-color: rgb(255, 225, 186);
}

.project-tech {
    font-weight: 600;
    font-size: 1.3vw;
}

@media only screen and (max-width: 768px) {

    .project-tech {
        font-weight: 600;
        font-size: 1.5vw;
    }

    .project-phrase {
        font-size: 1.3vw;

    }
    
    .project p {
        font-size: 1.3vw;

    }
}