.about-m-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100vh;
    overflow: hidden;
    flex-direction: column;
  }

.about-m-screen {
    width: 100%;
    height: 100%;
    background-color: rgba(238, 238, 238, 0.131);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-m-screen h3 {
    position: relative;
    font-size: 8vw;

}
