.project-m-container {
    width: 100%;
    min-height: 110vh;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.project-m-screen {
    width: 25%;
    height: 45vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease-in-out;
}

.project-m {
    position: relative;
    width: 60vw;
    height: 60vw;
    border-radius: 60vw;
    border: 1px solid rgb(191, 191, 191);
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: 1s;
    font-size: 2vw;
    cursor: pointer;
    z-index: 1;
}

.project:hover{
    transform: scale(1.1);
    z-index: 2;
}

.project-m-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project-m div {
    transition: 0.5s ease-in-out;
}

.project-m h3 {
    font-size: 8vw;
    transition: 0.5s ease-in-out;
    margin: 0;
    font-weight:100
}

.project-m span {
    font-size: 4vw;
    color: rgb(255, 123, 0);
}

.project-m p {
    font-size: 3.5vw;
    color: rgb(46, 46, 46);
}

.project-m-bg {
    position: absolute;
    top:-15vw;
    left:-15vw;
    width: 90vw;
    height: 90vw;
    opacity: 0.4;
    transition: 0.6s;
    background: url(../img/project-01.svg);
}

.project-m-bg:hover {
    opacity: 0.9;
}


.project-m-bg2 {
    position: absolute;
    top:-15vw;
    left:-15vw;
    width: 90vw;
    height: 90vw;  
    opacity: 0.2;
    transition: 0.6s;
    background: url(../img/project-02.svg);
}

.project-m-bg2:hover {
    opacity: 0.9;
}

.project-m-bg {
    position: absolute;
    top:-15vw;
    left:-15vw;
    width: 90vw;
    height: 90vw;
    opacity: 0.4;
    transition: 0.6s;
    background: url(../img/project-01.svg);
}     
.project-m-bg2 {
    position: absolute;
    top:-15vw;
    left:-15vw;
    width: 90vw;
    height: 90vw;  
    opacity: 0.2;
    transition: 0.6s;
    background: url(../img/project-02.svg);
}

.project-bg {
    position: absolute;
    top:-5vw;
    left:-5vw;
    width: 30vw;
    height: 30vw;
    opacity: 0.4;
    transition: 0.6s;
    background: url(../img/project-01.svg);
}


.project-bg2 {
    position: absolute;
    top:-5vw;
    left:-5vw;
    width: 30vw;
    height: 30vw;
    opacity: 0.2;
    transition: 0.6s;
    background: url(../img/project-02.svg);
}

