@import url('https://fonts.googleapis.com/css2?family=Cantarell&family=Lato:wght@300;400&family=Manrope:wght@200&family=Maven+Pro&family=Montserrat:wght@200&family=Nunito:wght@200&display=swap');


.App {
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-family: 'Cantarell', sans-serif;
  background-image: url(./img/background-01.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  overflow: hidden;
}

.background {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  background-size: cover;
  background-repeat: no-repeat; 
  overflow: hidden;
  display: none
}

.navigation {
  height: 12vh;
  width: 55vw;
  padding-top: 8px;
  position: fixed;
  top:0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: start;
  align-items: start;
  box-sizing: border-box;
  transition: 1s;
  overflow: hidden;
}

.navigation:before {
  content:'';
  position: absolute;
  top: 34px;
  width: 60%;
  height: 1px;
  border-bottom: 1px solid rgb(191, 191, 191);
}

.main {
  width: 100vw;
  position: relative;
  top: 10vh;
  display: flex;
  justify-content: center;
  align-items: start;
  overflow: hidden;
}

.main-title {
  height: 100vh;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: start;

}

.main-title-fixed {
  position: fixed;
  transition: 2s;
}

.main-content {
  display: flex;
  flex-direction: column;
  width: 100%;

}

.content-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  height: 100vh;
  overflow: hidden;
}



.grid-container {
  position: relative;
  width: fit-content;
  height: fit-content;
  padding: 3px;
  display: grid;
  margin: 0.05vw 1vw;
  gap: 0.1vw;
  position: relative;
  transition: 0.5s;
  z-index: 102;
  gap: 0px
}

.grid-nav-container {
  position: relative;
  width: fit-content;
  height: fit-content;
  padding: 3px;
  display: grid;
  margin: 0.05vw 1vw;
  gap: 0.1vw;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
  gap: 0px;
}

.grid-nav-container:before {
  content:'';
  position: absolute;
  display: inline-block;
  height: 1px;
  width: 100%;
  background-color: rgb(96, 96, 96);
  bottom:-5px;
  left: 0;
  z-index: 102;
  transition: 0.5s;
  opacity: 0;
  transform: scaleX(0.05);
}

.grid-nav-container:after {
  content:'';
  position: absolute;
  display: inline-block;
  height: 1px;
  width: 100%;
  background-color: rgb(96, 96, 96);
  bottom:-5%;
  left: 0;
  z-index: 102;
  transition: 0.3s;
  transition-delay: 0.1s;
  transform: scaleX(0.05);
  opacity: 0;
}

.grid-nav-container:hover:before {
  opacity: 1;
  transform: scaleX(1);
}

.grid-nav-container:hover:after {
  opacity: 1;
  transform: scaleX(1);
  bottom: 100%
}

.grid-item {
  position: relative;
  transition: 0.2s;
  background-color: rgb(252, 175, 98);

}

.grid-nav-item {
  position: relative;
  transition: 0.2s;
  width: 0.1vw;
  height:0.1vw;
}

.grid-item-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.footer {
  height: 12vh;
  width: 55vw;
  padding-top: 8px;
  position: fixed;
  bottom:0;
  right: 0;
  z-index: 100;
  background: linear-gradient(76deg, rgba(175,175,175,0.7) 0%, rgba(200,200,200,0.7) 46%, rgba(255,158,102,0.7) 70%, rgba(111,111,111,0.7) 100%);
  display: flex;
  justify-content: end;
  align-items: end;
  box-sizing: border-box;
  transition: 1s;
  overflow: hidden;
}

.contact {
  position: relative;
  height: 4vh;
  width: 4vh;
  margin: 1vh;
  background-repeat: no-repeat;
  z-index: 102;
  transition: 0.3s;
}

.linkedin {
  background: url(./img/linkedin.svg);
  background-size: contain;
}

.mail {
  background: url(./img/mail.svg);
  background-size: contain;
}

.github {
  background: url(./img/github.svg);
  background-size: contain;
}

.contact:hover{
  transform: scale(1.2);
}

.version {
  position: fixed;
  left: 1vw;
  bottom: 1vh;
  font-size: 14px;
  z-index: 300;
}

@media only screen and (max-width: 768px)  {

  .navigation {
    height: 10vh;
    width: 100vw;
    padding-top: 1vh;
    display: flex;
    justify-content: space-between;
    align-items: start;
    clip-path:none
  }

  .grid-nav-item {
    position: relative;
    transition: 0.2s;
    width: 0.28vw;
    height:0.28vw;
  }

  .main {
    width:100vw
  }

  .main-title {
    width: 0;
  }


  .grid-nav-container:before {
    height: 1px;
  }
  
  .grid-nav-container:after {
    height: 1px;
  }

  .footer {
    width: 100vw;
  }

  .version {
    bottom: 0;
    font-size: 10px;
  }

}

@media only screen and (max-width: 768px) and (orientation: landscape) {
  .footer{
    height: 15vh;
  }

  .contact {
    position: relative;
    height: 7vh;
    width: 7vh;
    margin: 1vh;
    background-repeat: no-repeat;
    z-index: 102;
    transition: 0.3s;
  }

}