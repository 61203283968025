.design-m-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.712);
  margin: 20vh 0
}

.design-m-intro {
  width: 100%;
  transition: 0.3s;
  overflow: hidden;
  padding: 5vw;
  box-sizing: border-box;
}

.design-m-intro div {
  width: 90%;
  transition: 0.3s;
}

.design-m-nav{
  margin-top: 1vh;
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid rgba(133, 133, 133, 0.9);
  margin-bottom: 2px;
}

.design-m-nav button {
  width: 33.3%;
  outline: none;
  border: 0;
  padding: 1vh;
  cursor: pointer;
  transition: 0.2s;
  font-family: 'Cantarell', sans-serif;
}

.design-m-nav button:hover {
  color:rgb(51, 51, 51)
}

.design-m-gallery {
 width: 100%;
 display: grid;
 grid-template-columns: 1fr 1fr 1fr;
 row-gap: 0;
}

.design-m-modal {
  position: fixed;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(56, 56, 56, 0.85);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  clip-path: polygon(0% 1%, 14% 1%, 14% 0%, 27% 0%, 27% 2%, 36% 2%, 36% 1%, 47% 1%, 47% 0%, 68% 0%, 68% 2%, 78% 2%, 78% 0%, 100% 0, 100% 98%, 87% 98%, 87% 100%, 74% 100%, 74% 98%, 64% 98%, 64% 99%, 51% 99%, 51% 97%, 35% 97%, 35% 99%, 7% 99%, 7% 100%, 0% 100%);
}

.design-m-modal img{
  width: 100%;
  height: auto 
}

.design-m-modal-text {
  position: absolute;
  bottom: 0;
  height: 22vh;
  width: 100%;
  color: white;
  background-color: rgba(56, 56, 56, 0.70);
  padding: 2vh;
  box-sizing: border-box;
  clip-path: polygon(0% 2%, 9% 2%, 9% 4%, 24% 4%, 24% 1%, 55% 1%, 55% 7%, 68% 7%, 68% 4%, 76% 4%, 76% 0, 91% 0, 91% 7%, 100% 7%, 100% 100%, 0 100%);
}


.mini-m-clip {
  width: 30vw;
  height: 30vw;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: 0.2s;
  filter:grayscale(0.7)
 }

 .mini-m-clip:hover {
  filter:grayscale(0)
 }
