.design-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.design-block-shadow {
    position: absolute;
    width: 80%;
    height: 75.5vh;
    transition: 1s;
    background: linear-gradient(50deg, rgba(111,111,111,0) 45%, rgba(233,151,103,0.2) 45%, rgba(255,158,102,0.2) 56%, rgba(133,132,132,0.2) 71%, rgba(111,111,111,0.2) 83%, rgba(111,111,111,0) 90%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

.design-block-shadow:before {
  content: '';
  position: absolute;
  top:2.8vw;
  right:4.5vw;
  width: 3vw;
  height: 3vw;
  border-radius: 3vw;
  border-top: 1px solid rgb(220, 220, 220);
  border-right: 1px solid rgb(220, 220, 220);
}

.design-block-shadow:after {
  content: '';
  position: absolute;
  top:0;
  right:0;
  width: 4vw;
  height: 4vw;
  border-radius: 4vw;
  border: 1px solid rgb(220, 220, 220);
}

.design-about {
    width: 20vw;
    margin-right: 5vw;
    font-size: 0.9vw;
    transition: 0.2s;
}

.design-though {
  width: 2.5vw;
  height: 2.5vw;
  border-radius: 3vw;
  transition: 0.2s;
  background-color: #ffcf82;
  color: white;
  text-align: center;
  line-height: 2.5vw;
  font-size: 1.5vw;
  font-weight: 900;
  margin-right: 3vw;
  border-top: 1px solid rgba(149, 149, 149, 0.2);
  border-right: 1px solid rgba(149, 149, 149, 0.2);
  transform: rotate(15deg);
}

.design-block-main {
  width: 85vw;
  height: 75vh;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color:rgba(255,255,255,0.85);
  transition: 1s,
}

.design-gallery-block {
    width: 60vw;
    display: flex;
    justify-content: center;
    position: relative;

}

.container {
    position: relative;
    width: 40vw;
    height: 60vh;
    margin: 40px 0;
    transition: 1s;
    border-top: 1px solid rgb(191, 191, 191);
    border-bottom: 1px solid rgb(191, 191, 191);
   }
  
  .clip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s;
    cursor: pointer;
    background-size: cover ;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgb(180, 180, 180);
  }
  
  .clip1 {
    clip-path: polygon(0% 0, 0% 23%, 0% 23%, 0% 44%, 0% 44%, 0% 100%, 66% 100%, 66% 72%, 71% 72%, 71% 29%, 80% 29%, 80% 0);
  }
  
  .clip2 {
    clip-path: polygon(21% 0, 21% 23%, 33% 23%, 33% 44%, 38% 44%, 38% 100%, 66% 100%, 66% 72%, 69% 72%, 69% 29%, 75% 29%, 75% 0);
  }
  
  .clip3 {
    clip-path: polygon(72% 0, 72% 29%, 69% 29%, 69% 72%,  66% 72%, 66% 100%, 100% 100%, 100% 72%, 100% 72%, 100% 29%, 100% 29%, 100% 0);
  }
  
  .clip-selected {
    clip-path: polygon(0% 0, 0% 23%, 0% 23%, 0% 44%, 0% 44%,0% 100%, 100% 100%, 100% 72%, 100% 72%, 100% 29%, 100% 29%, 100% 0);
    transition: 1s;
    z-index: 1;
    background-color: white;
  }

 .mini-clip {
  width: 7vw;
  height: 7vw;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: 0.2s;
  filter:grayscale(0.7)
 }

 .mini-clip:hover {
  filter:grayscale(0)
 }

  .clip-selected:hover {
    clip-path: polygon(0% 0, 0% 23%, 0% 23%, 0% 44%, 0% 44%,0% 100%, 100% 100%, 100% 72%, 100% 72%, 100% 29%, 100% 29%, 100% 0);
  }

  .container:hover {
    transform: rotateY(0);
  }
  
  .container:hover .clip {
    clip-path: polygon(100% 0, 100% 23%, 100% 23%, 100% 44%, 100% 44%, 100% 100%, 100% 100%, 100% 72%, 100% 100%, 100% 29%, 100% 29%, 100% 0);
  }
  
  .container .clip:hover {
    clip-path: polygon(0% 0, 0% 23%, 0% 23%, 0% 44%, 0% 44%,0% 100%, 100% 100%, 100% 72%, 100% 72%, 100% 29%, 100% 29%, 100% 0);
  }

.design-info-block {
    width: 25vw;
    height: 90%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.design-info-details {
  display: flex;
  flex-direction: column;
}

.design-highlight {
  color: black;
  font-weight: 600;
  font-size: 1.1vw;
}

.design-info-details-header {
  color: rgb(255, 123, 0);
  font-size: 1.5vw;
  margin-bottom: 0.5vw;
  margin-top: 0
}

.design-info-details-text {
  height: 2.8vw;
  font-size: 0.9vw;
  margin-bottom: 0.5vw
}

.design-info-details-other {
  display: flex;
  margin: 0.2vw;
}

.design-info-details-company {
  font-size: 1vw;
  text-align: center;
}

.design-info-details-year {
  padding: 2px;
  width: 50px;
  color: rgb(255, 123, 0);
  border-radius: 50px;
  text-align: center;
  position: relative;
}

.design-gallery{
  height: 70%;
  width: 90%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  .design-block-shadow {
    justify-content: start;
    width: 90%;
  }

  .design-about {
  width: 23.5vw;
  margin-right: 5vw;
  font-size: 1.6vw;
  transition: 0.2s;
}

.design-highlight {
  font-size: 1.8vw;
}

.design-info-details-header {
  font-size: 2.5vw;
  margin-bottom: 0.5vw;
  margin-top: 0
}

.design-info-details-text {
  height: 5vw;
  font-size: 1.5vw;
  margin-bottom: 1.2vw
}

.design-info-details-company {
  font-size: 1.5vw;

}

.design-info-details-year {
  padding: 0.1vw;
  width: 5vw;
  font-size: 1.5vw;
}

.design-info-details-other {
  display: flex;
  margin: 0
}


}

